/*
 * @Descripttion: 
 * @Author: Fanghong
 * @Date: 2023-02-09 14:55:40
 * @LastEditors: Shengping
 */

/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: 'homePage',
    component: () => import('@/views/HomePage/HomePage.vue'),
  },
  {
    path: "/videoPage",
    name: 'videoPage',
    component: () => import('@/views/VideoPage/VideoPage.vue'),
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/AppPage/AppPage.vue')
  },
  {
    path: '/love',
    name: 'love',
    component: () => import('@/views/BCouples/BCouples.vue')
  },
  {
    path: "/videoBox",
    name: 'videoBox',
    component: () => import('@/views/VideoBox/VideoBox.vue'),
  },
  {
    path: "/super",
    name: 'super',
    component: () => import('@/views/SuperControl/SuperControl.vue'),
  },
  {
    path: '/d',
    name: 'd',
    component: () => import('@/views/ChildDownload/ChildDownload.vue')
  },
  {
    path: '/p',
    name: 'p',
    component: () => import('@/views/ParentDownload/ParentDownload.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutPage/AboutPage.vue')
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('@/views/FaqUs/FaqUs.vue')
  }, {
    path: '/FaqItem',
    name: 'FaqItem',
    component: () => import('@/views/FaqUs/FAQItem.vue')
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
