<!--
 * @Descripttion: 
 * @Author: Fanghong
 * @Date: 2023-02-09 19:13:40
 * @LastEditors: Shengping
-->
<template>
  <div>
    <div class="mobile-head" v-if="isMobile === '1'">
      <div class="flex-between" style="padding: 12px 18px;">
        <img src="@/assets/img/logo.png" class="mo-logo" />
        <el-popover width="200" trigger="manual" placement="bottom-start" v-model="visible">
          <div class="mobile-menu-item" @click="routerPush('/', '0')"
            :style="{ color: `${tabs === '0' ? '#fff' : '#000'}`, backgroundColor: `${tabs === '0' ? '#00A09A' : '#fff'}` }">
            首页</div>
          <div class="mobile-menu-item" @click="routerPush('/app', '1')"
            :style="{ color: `${tabs === '1' ? '#fff' : '#000'}`, backgroundColor: `${tabs === '1' ? '#00A09A' : '#fff'}` }">
            皆成守护</div>
          <div class="mobile-menu-item" @click="routerPush('/love', '2')"
            :style="{ color: `${tabs === '2' ? '#fff' : '#000'}`, backgroundColor: `${tabs === '2' ? '#00A09A' : '#fff'}` }">
            情侣守护</div>
          <div class="mobile-menu-item" @click="routerPush('/FAQ', '3')"
            :style="{ color: `${tabs === '3' ? '#fff' : '#000'}`, backgroundColor: `${tabs === '3' ? '#00A09A' : '#fff'}` }">
            问题指南</div>
          <div class="mobile-menu-item" @click="routerPush('super', '4')"
            :style="{ color: `${tabs === '4' ? '#fff' : '#000'}`, backgroundColor: `${tabs === '4' ? '#00A09A' : '#fff'}` }">
            超级管控</div>
          <el-button slot="reference" @click="visible = !visible"><i class="el-icon-s-fold"></i></el-button>
        </el-popover>
      </div>
    </div>

    <div class="head-tabs" v-if="isMobile === '2'">
      <div class="flex-between">
        <img src="@/assets/img/logo.png" style="margin-left: 56px" />
        <div class="head-right flex-evenly">
          <router-link class="head-link pointer" target="_blank" active-class="head-link-actve" to="/">首页</router-link>
          <router-link class="head-link pointer" target="_blank" active-class="head-link-actve"
            to="/app">皆成守护</router-link>
          <router-link class="head-link pointer" target="_blank" active-class="head-link-actve"
            to="/love">皆成情侣定位守护</router-link>
          <router-link class="head-link pointer" target="_blank" active-class="head-link-actve" to="/super">超级管控助手<span
              class="link-bar">new！</span></router-link>
          <router-link class="head-link pointer" target="_blank" active-class="head-link-actve"
            to="/FAQ">问题指南</router-link>
          <router-link class="head-link pointer" target="_blank" active-class="head-link-actve"
            to="/about">关于我们</router-link>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

  </div>
</template>
<script>
const mkEnvJS = require('@/assets/js/mkEnv.js');
export default {
  data() {
    return {
      isMobile: '0',
      visible: false,
      tabs: '0',
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    // 页面初始化
    init() {
      if (mkEnvJS.isMobile) {
        this.isMobile = '1';
      } else {
        this.isMobile = '2';
      }
    },

    // 页面跳转
    routerPush(path, num) {
      this.$router.push({ path: path });
      this.tabs = num;
      this.visible = false;
    },
  },
};
</script>

<style>
@import url('./views/HomePage/home.css');
@import url('./assets/css/base.css');
@import url('./assets/css/mobile.css');
@import url('./assets/css/swiper.css');

#wrap-mobile,
#wrap-pc {
  /* display: none; */
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.fixed-width {
  width: 1200px;
  margin: 0 auto;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination-bullet-active {
  background: #0fccaf !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>